import { defineStore } from 'pinia';
import { OrderMinimalDto } from '@containex/portal-backend-dto';

interface OrderState {
    orders: OrderMinimalDto[];
    totalOrderAmount: number;
}

export const useOrderStore = defineStore('order', {
    state: (): OrderState => ({
        orders: [],
        totalOrderAmount: 0,
    }),
});
