<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useOrderAction, useOrderQuery } from '@/account/composables/order';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import { type FetchPaginatedDataDto, PaginationStep, ProvisionType } from '@containex/portal-backend-dto';
    import { getLogger } from '@containex/logger';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import Column from 'primevue/column';
    import BlockUI from 'primevue/blockui';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useDateFormatter } from '@/composables/date-format';
    import { marketContainsProvision } from '@/util/marketContainsProvision';
    import { useMarketQuery } from '@/composables/market';

    const { t } = useI18n();
    const { orders, totalOrderAmount } = useOrderQuery();
    const orderAction = useOrderAction();
    const logger = getLogger('OrderHistoryView');
    const { formatPrice } = usePriceFormatter();
    const { dateFormatter } = useDateFormatter();
    const { market } = useMarketQuery();

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const hasMarketRentalProvisionType = computed(() => {
        if (market.value != null) {
            return marketContainsProvision(market.value, ProvisionType.Rental);
        }
        return false;
    });

    const task = useAsyncTask(async () => {
        await orderAction.loadOrders(paginatorCurrentRows.value, PaginationStep.New);
    }).perform();

    const fetchPaginatedOrders = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('OrderHistoryView', 'Fetching orders for table');
        isLoading.value = true;
        await orderAction.loadOrders(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchPaginatedOrders.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchPaginatedOrders.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalOrderAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>

<template>
    <div class="order-history-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ORDER.HISTORY') }}</h2>
        <div v-if="task.isSuccessful" class="cart-container">
            <BlockUI :blocked="fetchPaginatedOrders.isRunning" class="full-width">
                <DataTable
                    v-if="orders.length > 0 || fetchPaginatedOrders.isRunning"
                    class="order-table"
                    :lazy="true"
                    :value="orders"
                    paginator
                    :total-records="totalOrderAmount"
                    :rows="paginatorCurrentRows"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :loading="isLoading"
                    :page-link-size="paginatorPageLinkSize"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                >
                    <Column field="external_id" :header="t('ORDER.ORDER_NUMBER')">
                        <template #body="slotProps">
                            {{ slotProps.data.external_id ?? slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="created_at" :header="t('ORDER.ORDER_DATE')">
                        <template #body="slotProps">
                            {{ dateFormatter.formatDate(new Date(slotProps.data.created_at)) }}
                        </template>
                    </Column>
                    <Column
                        v-if="hasMarketRentalProvisionType"
                        field="order_type_is_rental"
                        :header="t('ORDER.ORDER_TYPE')"
                    >
                        <template #body="slotProps">
                            {{
                                slotProps.data.order_type_is_rental
                                    ? t('ORDER.ORDER_TYPE_RENTAL')
                                    : t('ORDER.ORDER_TYPE_BUY')
                            }}
                        </template>
                    </Column>
                    <Column header-class="subtotal-column-header" field="subtotal" :header="t('ORDER.ORDER_AMOUNT')">
                        <template #body="slotProps">
                            <div class="subtotal-column">
                                {{ formatPrice(slotProps.data.subtotal) }}
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <div v-else>
                    <p>{{ t('ORDER.NO_ORDERS') }}</p>
                </div>
            </BlockUI>
        </div>
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .order-history-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-5;
    }

    :deep(.subtotal-column-header .p-column-header-content) {
        display: block;
        text-align: right;
    }

    .subtotal-column {
        text-align: right;
    }

    .cart-container {
        display: flex;
        flex-direction: row;
        gap: main.$spacing-5;
    }

    .cart-main-part {
        flex-grow: 1;
    }

    .cart-sidebar {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: main.$spacing-6;
    }

    .cart-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .order-table {
        margin-top: main.$spacing-5;
    }
</style>
