import { useOrderStore } from '@/account/stores/orders';
import { computed, type ComputedRef } from 'vue';
import { OrderMinimalDto, PaginationStep } from '@containex/portal-backend-dto';
import { ordersApi } from '@containex/portal-backend-api-client';
import useGlobalToast from '@/composables/useGlobalToast';
import { httpClient } from '@/common/api/http-client';

export interface OrderQuery {
    orders: ComputedRef<OrderMinimalDto[]>;
    totalOrderAmount: ComputedRef<number>;
}

export interface OrderAction {
    loadOrders(orderAmount: number, step: PaginationStep): Promise<void>;
}

export function useOrderQuery(): OrderQuery {
    const store = useOrderStore();

    return {
        orders: computed(() => store.orders),
        totalOrderAmount: computed(() => store.totalOrderAmount),
    };
}

export function useOrderAction(): OrderAction {
    const store = useOrderStore();

    return {
        async loadOrders(orderAmount: number, step: PaginationStep): Promise<void> {
            try {
                const orders = store.orders;
                let pageCursor;

                if (step === PaginationStep.New || orders == null) {
                    pageCursor = null;
                } else {
                    const filterOrder = step === PaginationStep.Next ? orders[orders.length - 1] : orders[0];
                    pageCursor = {
                        id: filterOrder?.display_id.toString(),
                    };
                }

                const response = await ordersApi.fetchPaginatedOrders(httpClient, {
                    orderAmount: String(orderAmount),
                    step,
                    pageCursorId: pageCursor?.id,
                });

                if (response.data != null) {
                    store.orders = response.data.orders;
                    store.totalOrderAmount = response.data.totalOrderAmount;
                }
            } catch {
                const { errorToastOptions, addToast } = useGlobalToast();

                store.orders = [];
                store.totalOrderAmount = 0;

                addToast(errorToastOptions);
            }
        },
    };
}
